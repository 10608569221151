// amplify-config-loader.js
import AwsExportProps from './aws-exports-props';

let config: AwsExportProps;

console.log('ConfigLoader: ' + process.env.REACT_APP_NODE_ENV);

switch (process.env.REACT_APP_NODE_ENV) {
    case 'development':
        config = require('./aws-exports-dev').default;
        break;
    case 'staging':
        config = require('./aws-exports-staging').default;
        break;
    case 'production':
        config = require('./aws-exports-prod').default;
        break;
    default:
        throw new Error(`Unknown environment: ${process.env.REACT_APP_ENV}`);
}

export default config;