import { createSlice } from "@reduxjs/toolkit";
const themeSlice = createSlice({
  name: "theme",
  initialState: {
    darkMode: false,
  },
  reducers: {
    toggleDarkMode: (state) => {
      state.darkMode = true;
    },
    toggleLightMode: (state) => {
      state.darkMode = false;
    },
  },
});
export const { toggleDarkMode, toggleLightMode } = themeSlice.actions;
export default themeSlice.reducer;