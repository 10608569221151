import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loading from "../components/loader/Loader";

const Home = lazy(() => import("../pages/Home"));
const Signup = lazy(() => import("../pages/signup"));
const SignIn = lazy(() => import("../pages/signIn"));
const MyNodes = lazy(() => import("../pages/MyNodes"));
const Earn = lazy(() => import("../pages/Earn"));
const LearnAndEarn = lazy(() => import("../pages/learnAndEarn"));
const Nodes = lazy(() => import("../pages/Nodes"));
const ForgetPass = lazy(() => import("../pages/ForgetPass"));
const Confirmation = lazy(() => import("../pages/confirmation"));
const Details = lazy(() => import("../pages/Details"));
const Sentiment = lazy(() => import("../pages/Sentiment"));
const Social = lazy(() => import("../pages/Social"));
const Teams = lazy(() => import("../pages/Teams"));
const FluxNode = lazy(() => import("../pages/FluxNode"));
const RpcNodeProviders = lazy(() => import("../pages/RpcNodeProviders"));
const Config = () => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* <------Regular Routes------> */}
          <Route path="/" element={<Home />}></Route>
          <Route path="/register" element={<Signup />}></Route>
          <Route path="/login" element={<SignIn />}></Route>
          <Route path="/nodes-my-dashboard" element={<MyNodes />}></Route>
          <Route path="/learn-and-earn-my-dashboard" element={<Earn />}></Route>
          <Route path="/learn-and-earn-research" element={<LearnAndEarn />}></Route>
          <Route path="/nodes-research" element={<Nodes />}></Route>
          <Route path="/forget-password" element={<ForgetPass />}></Route>
          <Route path="/confirmation" element={<Confirmation />}></Route>
          <Route path="/overview/:projectName" element={<Details />}></Route>
          <Route path="/sentiment/:projectName" element={<Sentiment />}></Route>
          <Route path="/socials/:projectName" element={<Social />}></Route>
          <Route path="/team/:projectName" element={<Teams />}></Route>
          {/* <Route path="/rpc-node-providers/:projectName" element={<RpcNodeProviders />}></Route> */}
          <Route path="/RPCNodeProviders/:projectName" element={<RpcNodeProviders />}></Route>
          <Route path="/project-node/:projectName/:nodeType" element={<FluxNode />}></Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default Config;
