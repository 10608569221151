import React, { Suspense, useEffect } from "react";
import "./App.css";
import Config from "./config/Config";

//import config from './aws-exports-dev';
import config from './aws-amplify-config-loader';
import Amplify from '@aws-amplify/core';
import ReactGA from 'react-ga4'

import { Helmet, HelmetProvider } from "react-helmet-async";

Amplify.configure(config);

ReactGA.initialize('G-B9914MDMYN');
//ReactGA.send({ hitType: "pageview", page: window.location.pathname});
// https://www.npmjs.com/package/react-ga4

function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname});
  }, []);

  return (
    <HelmetProvider>
      <div className="App">
        <Config />
      </div>
    </HelmetProvider>
  );
}

export default App;
